import Vue from 'vue'
import store from '../store/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: () => import('../views/ForgotPassword.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/reset_form/:token/:email',
    name: 'resetForm',
    component: () => import('../views/ResetForm.vue'),
    meta: { requiresAuth: false }
  },

  //http://reset_form/ae9f8953c93557fc1acba4d1ac2c2f5403c426243fc8d74d64cf79c1a9d71a49?email=vykintas@vlantana.lt
  // {
  //   path: '/',
  //   name: 'dashboard',
  //   component: () => import('../views/Dashboard.vue'),
  //   meta: { requiresAuth: true }
  // },
  // {
  //   path: '/order-list',
  //   name: 'OrderList',
  //   component: () => import('../views/OrderList.vue'),
  //   meta: { 
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/',
    name: 'OrderList',
    component: () => import('../views/OrderList.vue'),
    meta: { 
      requiresAuth: true
    }
  },
  {
    path: '/map',
    name: 'Map',
    component: () => import('../views/Map.vue'),
    meta: { 
      requiresAuth: true
    }
  },
  { 
    path: "*", 
    component: () => import('../components/NotFound.vue'),
  }
];


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// set permissions
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
});

export default router
