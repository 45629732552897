<template>
    <b-container fluid class="pa-0 ma-0">
        <div id="map" class="map"></div>
    </b-container>
</template>

<script>
import L from 'leaflet';
import {bus} from '../main';

export default {
    name: "Map",
    data: () => ({
        map: null,
        tileLayer: null,
        markersObj: [],
        markersLayer: [],
        errorSheet: false,
        errorMsg: '',
    }),
    computed: {
        markers() {
            console.log('markers map');
            //this.initItems();
            return this.$store.getters.markers
        },
        items() {
            console.log('items map');
            let items = this.$store.getters.items
            let searchFilter = this.$store.getters.searchFilter
            return this.filterItems(items, searchFilter)
        },
        transports() {
            return this.$store.getters.transports
        },
        totalItems() {
            let total = (this.items ? this.items.length : 0)
            console.log('count ' + total)
            if (this.items) {
                //this.initItems();
            }
            return total
        },
    },
    created() {
        console.log('map created');
        bus.$on('loadedDataFromApi', () => {
            console.log('markers bus');
            this.initMarkers()
        })
        bus.$on('loadTransportFromApi', () => {
            console.log('markers transport bus');
            this.initTransportMarkers()
        })
        bus.$on('changedSearchFilter', () => {
            console.log('markers bus');
            this.initMarkers()
        })

    },
    beforeDestroy() {
        bus.$off('loadedDataFromApi')
        bus.$off('loadTransportFromApi')
        bus.$off('changedSearchFilter')
    },
    mounted() {
        console.log('map mounted');
        this.initMap()
        this.$nextTick(
            this.initMarkers()
        )
    },
    methods: {
        initMarkers() {
            console.log('init markers start');

            this.markersLayer.clearLayers();

            let locationCoor = [];
            let marker;
            this.items.forEach(item => {
                if (
                    (item.truck !== null)
                    && item.status === 2
                    && (item.truck.latitude != null)
                    && (item.truck.longitude != null)
                ) {
                    let coord = [item.truck.latitude, item.truck.longitude]
                    let tooltip_text = item.truck.plate_number // + '<br>' + item.trailer.plate_number
                    let popup_text = '<dl class="popup">'
                    popup_text += '<dt>Order id</dt><dd >' + this.isText(item.client_order_id, '-') + '</dd>'
                    popup_text += '<dt>Company</dt><dd >' + this.isText(item.last_unloading_company, '-') + '</dd>'
                    popup_text += '<dt>Cargo</dt><dd >' + this.isText(item.cargo, '-') + '</dd>'
                    popup_text += '<dt>Status</dt><dd >' + this.matchStatus(item.status) + '</dd>'
                    popup_text += '<dt>Unloading</dt><dd >' + this.isText(item.last_unloading_place, '-') + '</dd>'
                    let sensors_text = ''
                    item.truck.sensors.forEach(sensor => {
                        if (sensor.type !== 'direction') {
                            sensors_text += '<dt>truck ' + sensor.type + '</dt><dd >' + this.isText(sensor.value, '-') + '</dd>'
                        }
                    });
                    item.trailer.sensors.forEach(sensor => {
                        if (sensor.type !== 'direction' && sensor.value !== null) {
                            sensors_text += '<dt>trailer ' + sensor.type + '</dt><dd >' + this.isText(sensor.value, '-') + '</dd>'
                        }
                    });
                    sensors_text = '<dl class="sensors">' + sensors_text + '</dl>'
                    popup_text += sensors_text
                    locationCoor.push(coord);

                    let popup = L.popup({
                        className: 'orderlistpopup',
                        maxWidth: 200,
                    })
                        .setLatLng(coord)
                        .setContent(popup_text)

                    let tooltip = L.tooltip({
                        maxWidth: 200,
                        permanent: true,
                        direction: 'bottom'
                    })
                        .setContent(tooltip_text);

                    let degree = this.getDegree(item.truck.sensors);
                    let iconcolor = (item.status === 5 ? '#176105' : '#302861')

                    marker = L.marker(coord, {
                        clickable: false,
                        icon: L.divIcon({
                            className: 'custom-div-icon',
                            html: `<div style='background-color:${iconcolor};' class='marker-pin'></div><i style="transform: rotate(${degree}deg)" class='material-icons'>arrow_upward</i>`,
                            iconSize: [30, 42],
                            iconAnchor: [15, 42],
                            popupAnchor: [1, -34],
                            tooltipAnchor: [1, -34],
                        })
                    })
                        .bindPopup(popup)
                        .bindTooltip(tooltip)

                    marker.on('mouseover', function () {
                        this.openPopup();
                    });
                    marker.on('mouseout', function () {
                        //this.closePopup();
                    });
                    this.markersLayer.addLayer(marker);
                }
            });
            if (locationCoor.length < 1) {
                this.map.setView([55.719196, 21.293514], 5);
            }
            if (locationCoor.length) {
                let bounds = new L.latLngBounds(locationCoor);
                setTimeout(() => {
                    this.map.fitBounds(bounds, {paddingTopLeft: [50, 50], paddingBottomRight: [50, 50]})
                }, 500)
            }
        },
        initTransportMarkers() {
            console.log('INIT TRANSPORT MARKER BUS')
            let marker;
            let locationCoor = [];
            this.transports.forEach(transport => {
                if ((transport.latitude != null) && (transport.longitude != null)) {
                    let coord = [transport.latitude, transport.longitude]
                    let tooltip_text = transport.plate_number
                    let popup_text = '<dl class="popup">'
                    let sensors_text = ''
                    transport.sensors.forEach(sensor => {
                        sensors_text += '<dt>truck ' + sensor.type + '</dt><dd >' + this.isText(sensor.value, '-') + '</dd>'
                    });
                    popup_text += '<dl class="sensors noborder">' + sensors_text + '</dl>'

                    locationCoor.push(coord);

                    let popup = L.popup({
                        className: 'orderlistpopup',
                        maxWidth: 200,
                    })
                        .setLatLng(coord)
                        .setContent(popup_text)

                    let tooltip = L.tooltip({
                        maxWidth: 200,
                        permanent: true,
                        direction: 'bottom'
                    })
                        .setContent(tooltip_text);

                    let degree = this.getDegree(transport.sensors);
                    let iconcolor = '#0015fd'

                    marker = L.marker(coord, {
                        clickable: false,
                        icon: L.divIcon({
                            className: 'custom-div-icon',
                            html: `<div style='background-color:${iconcolor};' class='marker-pin'></div><i style="transform: rotate(${degree}deg)" class='material-icons'>local_shipping</i>`,
                            iconSize: [30, 42],
                            iconAnchor: [15, 42],
                            popupAnchor: [1, -34],
                            tooltipAnchor: [1, -34],
                        })
                    })
                        .bindPopup(popup)
                        .bindTooltip(tooltip)


                    marker.on('mouseover', function () {
                        this.openPopup();
                    });
                    marker.on('mouseout', function () {
                        //this.closePopup();
                    });
                    this.markersLayer.addLayer(marker);
                    //console.log(markers);
                }
            })
        },

        initMap() {
            this.map = L.map('map', {
                inertia: false,
                zoomAnimation: false,
                fadeAnimation: false,
                markerZoomAnimation: false,
                zoomAnimationThreshold: false,
                animate: false,
            });

            this.tileLayer = L.tileLayer(
                'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png',
                {
                    maxZoom: 18,
                    zoomAnimationThreshold: 1,
                    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
                }
            );
            this.tileLayer.addTo(this.map);

            this.map.zoomControl.setPosition('topright');

            this.markersLayer = new L.LayerGroup()
            this.markersLayer.addTo(this.map);

        },
        matchStatus(status) {
            switch (status) {
                case 1:
                    return 'Planned';
                case 2:
                    return 'In progress';
                case 4:
                    return 'Rejected';
                case 5:
                    return 'Completed';
                default:
                    return 'Unknown';
            }
        },
        filterItems(items, searchtxt) {
            let self = this
            return items.filter(item => {
                return self.filterObject(item, searchtxt)
            })
        },
        filterObject(item, searchtxt) {
            let self = this
            return Object.keys(item).filter(key => {
                if (item[key] != null) {
                    if (typeof item[key] === 'object') {
                        return self.filterObject(item[key], searchtxt)
                    } else {
                        let value = item[key].toString()
                        return (value.toLowerCase().indexOf(searchtxt.toLowerCase()) !== -1)
                    }
                } else {
                    return false
                }
            }).length
        },
        isText(text, def) {
            return (text != null ? text : def)
        },
        getDegree(sensors) {
            let degree = 0;
            sensors.forEach(sensor => {
                if (sensor.type === 'direction') {
                    degree = this.isText(sensor.value, '0')
                }
            });
            return degree;
        },
    },
}
</script>

<style lang="scss">
.map {
    min-height: calc(100vh - 97px);
    z-index: 0;
    @media only screen and (max-width: 960px) {
        min-height: calc(100vh - 89px);
    }
}

.orderlistpopup dt::after {
    content: ":";
}

.orderlistpopup dl.popup {
    border: none;
    padding: 3px;
    margin-top: 10px;
}

.orderlistpopup dl.popup > dt {
    float: left;
    clear: left;
    min-width: 80px;
    text-align: right;
    font-weight: bold;
}

.orderlistpopup dl.popup dd {
    margin: 0 0 0 90px;
    padding: 0 0 2px 0;
}

.orderlistpopup dl.sensors {
    min-width: 150px;
    border: 1px solid #ccc;
    padding: 3px;
    margin-top: 10px;
}

.orderlistpopup dl.sensors dt {
    float: left;
    clear: left;
    min-width: 90px;
    text-align: right;
    font-weight: normal;
}

.orderlistpopup dl.sensors > dd {
    margin: 0 0 0 100px;
    padding: 0 0 2px 0;
}

.orderlistpopup dl.noborder {
    border: none;
}


.marker-pin {
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #176105;
    position: absolute;
    transform: rotate(-45deg);
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
}

.marker-pin::after {
    content: '';
    width: 24px;
    height: 24px;
    margin: 3px 0 0 3px;
    background: #fff;
    position: absolute;
    border-radius: 50%;
}

.custom-div-icon i {
    position: absolute;
    width: 22px;
    font-size: 22px;
    left: 0;
    right: 0;
    margin: 10px auto;
    text-align: center;
}

.container {
    width: 100% !important;
}
</style>