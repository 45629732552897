import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import './registerServiceWorker'
import router from './router/router'
import store from './store/store'
import vuetify from './plugins/vuetify';
import {ValidationObserver} from 'vee-validate';
import {ValidationProvider, extend} from 'vee-validate';
import {required} from 'vee-validate/dist/rules';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import 'leaflet/dist/leaflet.css'
import Axios from 'axios'
import Notifications from 'vue-notification'

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(Notifications)
// Install BootstrapVue
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import {Icon} from 'leaflet'

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

extend('required', {
    ...required,
    message: 'The {_field_} is required'
})

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.prototype.$http = Axios;


Axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        // handle error
        let errorMsg = null;
        if (typeof error !== 'undefined') {
            if (typeof error.response !== 'undefined' &&
                error.hasOwnProperty('response') &&
                error.response.hasOwnProperty('status')) {
                if (error.response.status === 401) {
                    console.log('Unauthorized');
                    store.dispatch('setautherror', 'Unauthorized')
                    store.dispatch('logout')
                    router.push('/login')
                } else if (error.response.status === 422) {
                    errorMsg = error.response.data.message
                }
            }
        }

        bus.$emit('networkError', errorMsg)
        return Promise.reject(error);
    });


Vue.config.productionTip = false
export const bus = new Vue();
Vue.use(bus);


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')


