/* eslint-disable no-mixed-spaces-and-tabs */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import qs from 'qs';

Vue.use(Vuex);

import {bus} from '../main'

export default new Vuex.Store({
    state: {
        APP_TITLE: 'VTrack',
        navigation: {
            rightHidden: false,
            leftHidden: false,
            left: false,
            right: false
        },
        layout: 'login-layout',
        loading: false,
        loadingMap: false,
        status: '',
        token: localStorage.getItem('token') || '',
        company: localStorage.getItem('company') || '',
        dateFrom: '',
        dateTo: new Date().toISOString().substr(0, 10),
        searchFilter: '',
        settings: JSON.parse(localStorage.getItem('settings')) || {
            header: ["no", "Map", "Order number", "Cargo", "Truck", "Semi-trailer", "Status"],
            barwidth: 1,
            orderbarmin: 400,
            orderbarmax: 800,
            dense: false,

        },
        user: {},
        order_param: [],
        orderItem: {},
        items: [],
        transport_list: [],
        transports: [],
    },
    mutations: {
        navigation(state, payload) {
            state.navigation[payload] = !state.navigation[payload]
        },
        setLayout(state, payload) {
            state.layout = payload
        },
        loading(state, value) {
            state.loading = value
        },
        loadingMap(state, value) {
            state.loadingMap = value
        },
        auth_request(state) {
            state.status = 'logging'
        },
        auth_success(state, {token, company}) {
            state.status = 'success'
            state.token = token
            state.company = company
        },
        auth_error(state, err) {
            state.status = err
        },
        logout(state) {
            state.status = ''
            state.token = ''
        },
        setItems(state, items) {
            state.items = items
        },
        setTransportList(state, transport_list) {
            state.transport_list = transport_list
        },
        setTransports(state, transports) {
            state.transports = transports
        },
        setOrderParam(state, order_param) {
            state.order_param = order_param
        },
        setOrderItem(state, item) {
            console.log(item)
            state.orderItem = item
        },
        setdateFrom: (state, newValue) => {
            state.dateFrom = newValue
        },
        setdateTo: (state, newValue) => {
            state.dateTo = newValue
        },
        setsearchfilter: (state, newValue) => {
            state.searchFilter = newValue
        },
        setdense: (state, newValue) => {
            state.settings.dense = newValue
        },
        setbarwidth: (state, newValue) => {
            state.settings.barwidth = newValue
        },
        setorderbarmin: (state, newValue) => {
            state.settings.orderbarmin = newValue
        },
        setorderbarmax: (state, newValue) => {
            state.settings.orderbarmax = newValue
        },
        setheaders: (state, newValue) => {
            state.settings.header = newValue
        },
    },
    actions: {
        navigationToggle({commit}, payload) {
            commit(payload.commit, payload.data);
        },
        setLoading({commit}, value) {
            commit('loading', value)
        },
        setLoadingMap({commit}, value) {
            commit('loadingMap', value)
        },
        defaultDate: ({commit}) => {
            let dateFrom = new Date();
            //console.log(dateFrom)
            dateFrom.setMonth(dateFrom.getMonth() - 1);
            commit('setdateFrom', dateFrom.toISOString().substr(0, 10))

            let dateTo = new Date();
            commit('setdateTo', dateTo.toISOString().substr(0, 10))
        },
        setdateFrom: ({commit, state}, newValue) => {
            commit('setdateFrom', newValue)
            return state.dateFrom
        },
        setdateTo: ({commit, state}, newValue) => {
            commit('setdateTo', newValue)
            return state.dateTo
        },
        setSearchFilter: ({commit}, newValue) => {
            if (newValue == null) {
                newValue = ''
            }
            commit('setsearchfilter', newValue)
            bus.$emit('changedSearchFilter')
        },
        setdense: ({commit, state}, newValue) => {
            commit('setdense', newValue)
            localStorage.setItem('settings', JSON.stringify(state.settings))
        },
        setbarwidth: ({commit, state}, newValue) => {
            commit('setbarwidth', newValue)
            localStorage.setItem('settings', JSON.stringify(state.settings))
        },
        setTransportMarkers: ({commit, state}, newValue) => {
            commit('setTransportMarkers', newValue)
            localStorage.setItem('transport_markers', state.transport_markers)
        },
        setorderbarmax: ({commit, state}, newValue) => {
            commit('setorderbarmax', newValue)
            localStorage.setItem('settings', JSON.stringify(state.settings))
        },
        setorderbarmin: ({commit, state}, newValue) => {
            commit('setorderbarmin', newValue)
            localStorage.setItem('settings', JSON.stringify(state.settings))
        },
        setheaders: ({commit, state}, newValue) => {
            commit('setheaders', newValue)
            localStorage.setItem('settings', JSON.stringify(state.settings))
        },
        setOrderParam: ({commit}, newValue) => {
            commit('setOrderParam', newValue)
        },
        setTransportList: ({commit}, newValue) => {
            commit('setTransportList', newValue)
        },
        setautherror: ({commit}, newValue) => {
            commit('auth_error', newValue)
        },
        resetForm({commit}, user) {
            let params = {
                email: user.email,
                password: user.password,
                password_confirmation: user.password_confirmation,
                token: user.token,
                client_id: 1,
                client_secret: 'iUcyrRDNp4mdzY7FI22QBscu4jnKO2mjaSQc3p3t',
                grant_type: 'password',
            };
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios({
                    url: process.env.VUE_APP_API_RESET_PASSWORD_URL,
                    data: qs.stringify(params),
                    method: 'POST',
                })
                    .then(resp => {
                        if (resp) {
                            if (resp.data.status === 401) {
                                reject(resp)
                            } else {
                                resolve(resp);
                            }
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        forgotPassword({commit}, user) {
            let params = {
                email: user.email,
                client_id: 1,
                client_secret: 'iUcyrRDNp4mdzY7FI22QBscu4jnKO2mjaSQc3p3t',
                grant_type: 'password',
            };
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios({
                    url: process.env.VUE_APP_API_FORGOT_PASSWORD_URL,
                    data: qs.stringify(params),
                    method: 'POST',
                })
                    .then(resp => {
                        if (resp) {
                            if (resp.data.status === 401) {
                                reject(resp)
                            } else {
                                resolve(resp);
                            }
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        register({commit}, user) {
            let params = {
                email: user.email,
                password: user.password,
                password_confirmation: user.password_confirmation,
                invitation: user.invitation,
                client_id: 1,
                client_secret: 'iUcyrRDNp4mdzY7FI22QBscu4jnKO2mjaSQc3p3t',
                grant_type: 'password',
            };
            return new Promise((resolve, reject) => {
                commit('auth_request')
                axios({
                    url: process.env.VUE_APP_API_REGISTER_URL,
                    data: qs.stringify(params),
                    method: 'POST',
                })
                    .then(resp => {
                        if (resp) {
                            resolve(resp);
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        login({commit}, user) {
            let params = {
                username: user.email,
                password: user.password,
                client_id: 1,
                client_secret: 'iUcyrRDNp4mdzY7FI22QBscu4jnKO2mjaSQc3p3t',
                grant_type: 'password',
            };
            return new Promise((resolve, reject) => {
                commit('auth_request');
                axios({
                    url: process.env.VUE_APP_API_LOGIN_URL,
                    data: qs.stringify(params),
                    method: 'POST',
                })
                    .then(resp => {
                        if (resp) {
                            const token = resp.data.access_token;
                            const company = user.username;
                            if (token) {
                                commit('auth_success', {token, company});
                                localStorage.setItem('token', token);
                                localStorage.setItem('company', company);
                                resolve(resp)
                            } else {
                                commit('auth_error', 'incorrect');
                                localStorage.removeItem('token');
                                localStorage.removeItem('company');
                                reject('incorrect')
                            }
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                commit('logout');
                localStorage.removeItem('token');
                localStorage.removeItem('company');
                resolve()
            })
        },
        loadDataFromApi({commit}) {
            return new Promise((resolve, reject) => {
                let params = {
                    from: this.state.dateFrom + ' 23:59:59',
                    to: this.state.dateTo + ' 23:59:59'
                };
                commit('loading', true);

                axios({
                    url: process.env.VUE_APP_API_DATA_URL,
                    params: params,
                    method: 'GET',
                    headers: {Authorization: 'Bearer ' + this.state.token}
                })
                    .then(resp => {
                        if (resp) {
                            console.log('loading data')
                            let items = resp.data;
                            if (items) {
                                let i = 1
                                items.forEach(item => {
                                    item['eilnr'] = i++;
                                });
                                commit('setItems', items)
                                // commit('setMarkers', items)
                                bus.$emit('loadedDataFromApi')

                                resolve(resp)
                            }
                        } else {
                            reject()
                        }
                        commit('loading', false);
                    })
                    .catch(err => {
                        commit('loading', false);
                        reject(err)
                    })

            })
        },
        loadTransportFromApi({commit}) {
            return new Promise((resolve, reject) => {
                axios({
                    url: process.env.VUE_APP_API_TRANSPORT_URL,
                    params: {transport_ids: this.state.transport_list},
                    method: 'GET',
                    headers: {Authorization: 'Bearer ' + this.state.token}
                }).then(resp => {
                    if (resp.data) {
                        console.log(resp.data)
                        commit('setTransports', resp.data.data)
                        resolve(resp)
                        bus.$emit('loadTransportFromApi')
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        loadOrderFromApi({commit}) {
            console.log('loading order start')
            commit('setOrderItem', null)
            commit('loadingMap', true);

            return new Promise((resolve, reject) => {
                console.log(this.state.order_param.order_id)
                axios({
                    url: process.env.VUE_APP_API_DATA_URL + '/' + this.state.order_param.order_id,
                    method: 'GET',
                    headers: {Authorization: `Bearer ${this.state.token}`}
                })
                    .then(resp => {
                        if (resp) {
                            console.log('loading order')
                            let item = resp.data;

                            if (item.data) {
                                console.log(item.data)
                                commit('setOrderItem', item.data[0])
                                bus.$emit('loadedOrderFromApi')

                                resolve(resp)
                            } else {
                                reject()
                            }
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })

            })
        },
    },
    getters: {
        APP_TITLE: state => state.APP_TITLE,
        items: state => state.items,
        transports: state => state.transports,
        transportList: state => state.transport_list,
        orderId: state => state.order_id,
        orderItem: state => state.orderItem,
        searchFilter: state => state.searchFilter,
        settings: state => state.settings,
        dense: state => state.settings.dense,
        company: state => state.company,
        navigation: state => state.navigation,
        loading: state => state.loading,
        loadingMap: state => state.loadingMap,
        layout: state => state.layout,
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status
    },
    modules: {}
});