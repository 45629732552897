<template>
    <v-app id="app">
        <NavBarTop/>
        <v-content fluid>
            <router-view/>
        </v-content>
        <events/>
        <MobileFooter/>
    </v-app>
</template>

<script>

import Events from "../components/Events";

const NavBarTop = () => import("../layout/NavBarTop"),
    NavBarLeft = () => import("../layout/NavBarLeft"),
    MobileFooter = () => import("../layout/MobileFooter");

export default {
    name: "MobileLayout",
    components: {Events, MobileFooter, NavBarTop, NavBarLeft},
}
</script>