<template>
    <v-app id="app">
        <router-view></router-view>
    </v-app>
</template>

<script>
export default {
    name: "LoginLayout",
}
</script>