<template>
    <component v-bind:is="layout"></component>
</template>
<script>

import AppLayout from './layout/AppLayout'
import MobileLayout from './layout/MobileLayout'
import LoginLayout from './layout/LoginLayout'

export default {
    components: {LoginLayout, AppLayout, MobileLayout},
    data: () => ({
        login: false,
    }),
    computed: {
        layout() {
            if (this.$store.getters.isLoggedIn) {
                if (this.$vuetify.breakpoint.smAndUp) {
                    return 'AppLayout';
                } else {
                    return 'MobileLayout';
                }
            } else {
                return 'LoginLayout';
            }
        }
    },
    created() {
        this.$http.interceptors.response.use(undefined, function (err) {
            return new Promise(function () {
                if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
                    this.$store.dispatch('logout')
                }
                throw err;
            });
        });
    },
    watch: {
        $route(to) {
            this.login = to.name === 'login'
        }
    },
}
</script>

<style lang="scss">
.gradient-hover {
    &:hover {
        background: linear-gradient(56deg, rgba(220, 220, 221, 1) 39%, rgba(255, 255, 255, 1) 100%);
    }
}

.underline {
    text-decoration: underline;
}

// * {
//    transition: all 0.3s ease-in-out;
//    box-sizing: border-box;
// }
</style>