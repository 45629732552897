import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi'
  },
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      dark: {
        primary: '#46494C',
        secondary: '#4C5C68',
        accent: '#1985A1',
        error: '#C5C3C6',
        info: '#DCDCDD',
        success: '#DCDCDD',
        warning: '#C5C3C6',
        background: '#fafafa'
      },
      light: {
        primary: '#302861',
        secondary: '#d31d4b',
        accent: '#524b7b',
        error: '#ff0000',
        info: '#b9b6ca',
        success: '#f9e1e7',
        warning: '#ff0000',
        background: '#fafafa'
      },
    },
    light: true,
  },
});
