<template>
    <v-app id="app">
        <NavBarTop/>
        <NavBarLeft/>
        <!-- <NavBarRight /> -->
        <v-content fluid>
            <Map/>
        </v-content>
        <events/>
        <Footer/>
    </v-app>
</template>

<script>

import Map from "../views/Map";
import Events from "../components/Events";

const NavBarTop = () => import("../layout/NavBarTop"),
    NavBarLeft = () => import("../layout/NavBarLeft"),
    Footer = () => import("../layout/Footer");

export default {
    name: "AppLayout",
    components: {Events, Map, Footer, NavBarTop, NavBarLeft},
}
</script>
<style scoped lang="scss">
</style>