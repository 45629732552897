<template>
<div v-if=false></div>
</template>

<script>

    export default {
        name: "Events",
        data () {
            return {
                timer: ''
            }
        },
        created () {
            this.$store.dispatch('defaultDate')
            this.loadDataFromApi()
            this.timer = setInterval(this.loadDataFromApi, 5*60*1000)
        },
        methods: {
            loadTransportFromApi () {
                this.$store.dispatch('loadTransportFromApi')
            },
            loadDataFromApi() {
                this.$store.dispatch('loadDataFromApi').then(
                    resolve => {
                        if (resolve.data) {
                            let transportIds = []
                            resolve.data.forEach(item => {
                                if(item.truck !== null) {
                                    transportIds.push(item.truck.transport_id)
                                }
                            })
                            this.$store.dispatch('setTransportList', transportIds)
                        }
                        this.$store.dispatch('loadTransportFromApi')
                    }
                )
            },
            cancelAutoUpdate () { 
                clearInterval(this.timer) 
            }
        },
        beforeDestroy () {
            clearInterval(this.timer)
        }
    }
</script>